var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sub-nav sticky"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sub-nav-list"},[_c('span',{staticClass:"nav-title"},[_vm._v("Post Job")]),_c('hr',{staticClass:"vline"}),_c('div',{staticClass:"sub-nav-group"},[_c('span',[_vm._v("Points Used")]),(_vm.form)?_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.form.getPoints))]):_vm._e()])])])]),_c('section',{staticClass:"section has-padding"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tab-list"},[_c('router-link',{staticClass:" tab-list-item",class:{
            done: [
              'description',
              'email',
              'application',
              'upgrade',
              'summary' ].includes(_vm.$route.name),
          },attrs:{"to":("/postjob/description?id=" + (_vm.$route.query.id) + "&type=" + (_vm.$route.query.type)),"disabled":!_vm.whateverActivatesThisLink,"event":_vm.whateverActivatesThisLink ? 'click' : ''}},[_c('span',{staticClass:"check"}),_vm._v(" Job Description ")]),_c('router-link',{staticClass:" tab-list-item",class:{
            done: ['application', 'upgrade', 'summary'].includes(_vm.$route.name),
          },attrs:{"to":("/postjob/email?id=" + (_vm.$route.query.id) + "&type=" + (_vm.$route.query.type)),"disabled":!_vm.whateverActivatesThisLink,"event":_vm.whateverActivatesThisLink ? 'click' : ''}},[_c('span',{staticClass:"check"}),_vm._v(" Email Setting ")]),_c('router-link',{staticClass:" tab-list-item",class:{ done: ['upgrade', 'summary'].includes(_vm.$route.name) },attrs:{"to":("/postjob/application?id=" + (_vm.$route.query.id) + "&type=" + (_vm.$route.query.type)),"disabled":!_vm.whateverActivatesThisLink,"event":_vm.whateverActivatesThisLink ? 'click' : ''}},[_c('span',{staticClass:"check"}),_vm._v(" Application Setting ")]),(
            _vm.$route.query.type !== 'online' && _vm.$route.query.type !== 'offline'
          )?_c('router-link',{staticClass:" tab-list-item",class:{ done: ['summary'].includes(_vm.$route.name) },attrs:{"to":("/postjob/upgrade?id=" + (_vm.$route.query.id) + "&type=" + (_vm.$route.query.type)),"disabled":!_vm.whateverActivatesThisLink,"event":_vm.whateverActivatesThisLink ? 'click' : ''}},[_c('span',{staticClass:"check"}),_vm._v(" Upgrade ")]):_vm._e(),_c('router-link',{staticClass:" tab-list-item",attrs:{"to":("/postjob/summary?id=" + (_vm.$route.query.id) + "&type=" + (_vm.$route.query.type)),"disabled":!_vm.whateverActivatesThisLink,"event":_vm.whateverActivatesThisLink ? 'click' : ''}},[_c('span',{staticClass:"check"}),_vm._v(" Summary ")])],1)]),(!_vm.rerender)?_c('div',{staticClass:"container"},[_c('transition',{attrs:{"name":"slideup"}},[(_vm.company)?_c('router-view',{attrs:{"iget":_vm.form,"getEmail":_vm.company},on:{"putdata":_vm.get}}):_vm._e()],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }