<template>
  <div>
    <div class="sub-nav sticky">
      <div class="container">
        <div class="sub-nav-list">
          <span class="nav-title">Post Job</span>
          <hr class="vline" />
          <div class="sub-nav-group">
            <span>Points Used</span>
            <span v-if="form" class="price">{{ form.getPoints }}</span>
          </div>
          <!-- <h3>Balance Used<span> {{this.$store.state.BalanceUsed.BalanceUsed}}</span>LAK</h3> -->
        </div>
      </div>
    </div>

    <section class="section has-padding">
      <div class="container">
        <div class="tab-list">
          <router-link
            :class="{
              done: [
                'description',
                'email',
                'application',
                'upgrade',
                'summary',
              ].includes($route.name),
            }"
            :to="
              `/postjob/description?id=${$route.query.id}&type=${$route.query.type}`
            "
            class=" tab-list-item"
            :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''"
          >
            <span class="check"></span>
            Job Description
          </router-link>
          <router-link
            :to="
              `/postjob/email?id=${$route.query.id}&type=${$route.query.type}`
            "
            class=" tab-list-item"
            :class="{
              done: ['application', 'upgrade', 'summary'].includes($route.name),
            }"
            :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''"
          >
            <span class="check"></span>
            Email Setting
          </router-link>
          <router-link
            :class="{ done: ['upgrade', 'summary'].includes($route.name) }"
            :to="
              `/postjob/application?id=${$route.query.id}&type=${$route.query.type}`
            "
            class=" tab-list-item"
            :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''"
          >
            <span class="check"></span>
            Application Setting
          </router-link>
          <router-link
            v-if="
              $route.query.type !== 'online' && $route.query.type !== 'offline'
            "
            :class="{ done: ['summary'].includes($route.name) }"
            :to="
              `/postjob/upgrade?id=${$route.query.id}&type=${$route.query.type}`
            "
            class=" tab-list-item"
            :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''"
          >
            <span class="check"></span>
            Upgrade
          </router-link>
          <router-link
            :to="
              `/postjob/summary?id=${$route.query.id}&type=${$route.query.type}`
            "
            class=" tab-list-item"
            :disabled="!whateverActivatesThisLink"
            :event="whateverActivatesThisLink ? 'click' : ''"
          >
            <span class="check"></span>
            Summary
          </router-link>
        </div>
      </div>

      <div class="container" v-if="!rerender">
        <transition name="slideup">
          <router-view
            v-if="company"
            :iget="form"
            :getEmail="company"
            @putdata="get"
          ></router-view>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import { GET_COMPANY_POFILE } from "../graphql/Company";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  layout: "default",
  data: () => ({
    rerender: false,
    company: null,
    form: null,
    whateverActivatesThisLink: true,
  }),
  async created() {
    await this.getCompanyPofile();
    if (this.$route.query.post) {
      this.whateverActivatesThisLink = false;
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.rerender = true;
        this.$nextTick(() => {
          this.rerender = false;
        });

        if (this.$route.query.post) {
          this.whateverActivatesThisLink = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    get(form) {
      this.form = form;
    },
    async getCompanyPofile() {
      const res = await apolloClient_Company.query({
        query: GET_COMPANY_POFILE,
      });
      this.company = res.data.profile;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  white-space: pre;
  color: var(--text-color);
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
}

.tab-list {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-around;
  white-space: nowrap;
  margin-bottom: 20px;

  @include mobile {
    justify-content: space-between;
    overflow-x: scroll;
  }

  &::after {
    content: "";
    display: block;
    background-color: var(--border-color);
    position: absolute;
    height: 1px;
    width: 100%;
    z-index: 0;
    top: 50%;

    @include mobile {
      top: 35%;
    }
  }

  .tab-list-item {
    cursor: pointer;
    z-index: 1;
    background-color: var(--grey-color);
    color: var(--dark-grey-color);
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--info-color);
      color: var(--text-color);
      .check {
        border-color: var(--text-color);
      }
    }
    .check {
      border: 2px solid var(--dark-grey-color);
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-right: 5px;
    }

    &.done {
      color: #fff;
      background-color: var(--primary-color);
      .check {
        border: 1px solid var(--primary-color);
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-right: 5px;
        position: relative;
        background-color: #fff;

        &::before {
          content: "";
          position: absolute;
          left: 4px;
          top: 8px;
          background: var(--primary-color);
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 var(--primary-color), 4px 0 0 var(--primary-color),
            4px -2px 0 var(--primary-color), 4px -4px 0 var(--primary-color),
            4px -6px 0 var(--primary-color), 4px -8px 0 var(--primary-color);
          transform: rotate(45deg);
        }
      }
    }

    &.router-link-active {
      background-color: var(--primary-color) !important;
      color: #fff;

      .check {
        border: 1px solid var(--primary-color);
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 4px;
          top: 8px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>
